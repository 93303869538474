import {
  Bin as _Bin,
  Token as BaseToken,
  Fraction,
  parseUnits,
  TokenAmount
} from '@dusalabs/sdk';
import { BigintIsh } from '@dusalabs/sdk/dist/constants';
import { Address } from '@massalabs/massa-web3';
import { CHAIN_ID, EXPLORER, NETWORK } from './config';
import {
  currentEpoch,
  epochDuration,
  genesis,
  genesisTimestamp,
  JELLY_VALUE,
  launchTimestamp,
  ONE_DAY,
  ONE_PERIOD,
  ONE_WEEK,
  POINTS_BY_JELLY,
  POINTS_BY_JELLY_BEFORE_37,
  unknownURI
} from './constants';
import { defaultDeposit } from './placeholders';
import { pools as _pools, Pool } from './pools';
import { getImportedTokens, getLastTokens } from './storage';
import { JELLY, MASSA, tokens } from './tokens';
import {
  Reward,
  Token as PrismaToken,
  GlobalLeaderboardItemDetails,
  RewardDetails
} from './trpc';
import {
  Bin,
  Vault,
  DayWindow,
  TimeWindow,
  Token,
  Epoch,
  WeekWindow
} from './types';

export const abs = (x: bigint): bigint => (x < 0n ? -x : x);

export const round = (x: number, precision = 2): number =>
  Math.round(x * 10 ** precision) / 10 ** precision;

export const roundPrice = (x: number): number =>
  round(x, x > 100 ? 2 : x > 1 ? 4 : 6);

export const formatNumber = (num: number, precision = 2): string => {
  return num.toLocaleString('en-US', {
    maximumSignificantDigits:
      num < 1 ? (precision <= 0 ? 1 : precision) : undefined,
    maximumFractionDigits: precision
  });
};

export const printBigintIsh = (
  token: Token,
  amount: BigintIsh,
  precision = 2
): string => formatNumber(roundTokenAmount(token, amount), precision);

export const roundTokenAmount = (token: Token, amount: BigintIsh) =>
  Number(new TokenAmount(token, amount).toFixed(token.decimals));

// ????
export const sortTokens = (tkns: Token[]): Token[] => {
  const sortTokenArray = ['MAS', 'WMAS', 'USDC.e', 'DAI.e', 'WETH.e', 'PUR']; // tokens.map(t => t.symbol)
  const sortTokenIndices = Object.fromEntries(
    sortTokenArray.map((symbol, index) => [symbol, index])
  );

  return tkns
    .sort((a, b) => (a.symbol.toUpperCase() < b.symbol.toUpperCase() ? -1 : 1))
    .sort((a, b) => {
      const aIndex =
        sortTokenIndices[a.symbol] !== undefined
          ? sortTokenIndices[a.symbol]
          : sortTokenArray.length;
      const bIndex =
        sortTokenIndices[b.symbol] !== undefined
          ? sortTokenIndices[b.symbol]
          : sortTokenArray.length;

      if (aIndex !== bIndex) {
        return aIndex - bIndex;
      }
      return a.symbol.localeCompare(b.symbol);
    });
};

export const unFormatPrintedNumber = (printedNumber: string): string => {
  return printedNumber.replace(/,/g, '');
};

export const roundFractionPrice = (fraction: Fraction): string => {
  const value =
    Number(fraction.quotient) +
    Number(fraction.remainder.numerator) /
      Number(fraction.remainder.denominator || 1);

  let decimalPlaces = 18;
  if (value > 100) decimalPlaces = 2;
  else if (value > 1) decimalPlaces = 4;

  return formatNumberWithSubscriptZeros(
    fraction.toFixed(decimalPlaces),
    value < 1 ? 5 : 4
  );
};

export const printUSD = (value: number, keepCents = true, precision = 2) =>
  formatNumberWithSubscriptZeros(
    value.toLocaleString('en-US', {
      maximumSignificantDigits: value < 1 ? precision : undefined,
      maximumFractionDigits: keepCents ? precision : 0,
      minimumFractionDigits: keepCents ? precision : 0
    })
  );

export const toFraction = (float: number): Fraction => {
  if (isNaN(float) || float === 0) return new Fraction(0n);
  const value = BigInt(Math.round(float * 1e40));
  return new Fraction(value, BigInt(1e40));
};

export const minmax = (val: number, min: number, max: number) =>
  Math.min(Math.max(min, val), max);

export const blockInvalidChar = (
  e: React.KeyboardEvent,
  additionalString: string[] = ['']
) => {
  ['e', 'E', '+', '-'].concat(additionalString).includes(e.key) &&
    e.preventDefault();
};

export const isAddressValid = (address: string): boolean => {
  if (!address) return false;
  try {
    new Address(address);
    return true;
  } catch (e) {
    return false;
  }
};

export const printAddress = (address: string) =>
  `${address.substring(0, 5)}...${address.substring(address.length - 5)}`;

// ********************************

export const printDate = (
  date: Date | string | number,
  format?: TimeWindow | DayWindow | WeekWindow,
  withYear = false
) => {
  let formatOptions: Intl.DateTimeFormatOptions = {
    year: withYear ? 'numeric' : undefined
  };
  switch (format) {
    case TimeWindow.Day:
      formatOptions = {
        hour: '2-digit',
        minute: '2-digit'
      };
      break;
    case TimeWindow.Week:
    case DayWindow.WEEK:
    case TimeWindow.Month:
    case DayWindow.MONTH:
    case DayWindow.QUARTER:
    case DayWindow.HALF_YEAR:
    case WeekWindow.QUARTER:
    case WeekWindow.HALF_YEAR:
    case WeekWindow.YEAR:
      formatOptions = {
        month: 'short',
        day: '2-digit'
      };
      break;
    default:
      formatOptions = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      };
      break;
  }
  return new Date(date).toLocaleString(undefined, formatOptions);
};

export const getTokenFromAddress = (address: string): Token => {
  const tokenList = tokens.concat(getImportedTokens());

  const token = tokenList.find((t) => t.address === address);
  if (!token) {
    throw new Error(`Token ${address} not found.`);
  }
  return token;
};

export const getLogoURIFromToken = (token: Token): string => {
  if (token.logoURI) return token.logoURI;
  else {
    try {
      return getTokenFromAddress(token.address).logoURI;
    } catch {
      return unknownURI;
    }
  }
};

export const getTokensFromStorage = (): (Token | undefined)[] => {
  const lastTokens = getLastTokens();

  const tokensFromStorage = lastTokens.map((token) => {
    return tokens
      .concat(getImportedTokens())
      .find((t) => (token ? t.equals(token) : false));
  });

  return !tokensFromStorage.length ? [MASSA, undefined] : tokensFromStorage;
};

export const getPriceFromId = _Bin.getPriceFromId;
export const getIdFromPrice = _Bin.getIdFromPrice;
export const getIdSlippageFromPriceSlippage =
  _Bin.getIdSlippageFromPriceSlippage;

export const getIntervalFromSeconds = (nbSeconds: number): string[] => {
  let remainingSeconds = nbSeconds;

  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;
  const secondsInWeek = 7 * secondsInDay;
  const secondsInMonth = 30 * secondsInDay; // Approximation

  const months = Math.floor(remainingSeconds / secondsInMonth);
  remainingSeconds %= secondsInMonth;

  const weeks = Math.floor(remainingSeconds / secondsInWeek);
  remainingSeconds %= secondsInWeek;

  const days = Math.floor(remainingSeconds / secondsInDay);
  remainingSeconds %= secondsInDay;

  const hours = Math.floor(remainingSeconds / secondsInHour);
  remainingSeconds %= secondsInHour;

  const minutes = Math.floor(remainingSeconds / secondsInMinute);
  remainingSeconds %= secondsInMinute;

  return [
    months > 0 ? `${months} month${months !== 1 ? 's' : ''}` : '',
    weeks > 0 ? `${weeks} week${weeks !== 1 ? 's' : ''}` : '',
    days > 0 ? `${days} day${days !== 1 ? 's' : ''}` : '',
    hours > 0 ? `${hours} hour${hours !== 1 ? 's' : ''}` : '',
    minutes > 0 ? `${minutes} minute${minutes !== 1 ? 's' : ''}` : '',
    remainingSeconds > 0
      ? `${remainingSeconds.toFixed(0)} second${
          remainingSeconds !== 1 ? 's' : ''
        }`
      : ''
  ].filter(Boolean);
};

// prettier-ignore
export const emptyBin: Bin = { id: 0, amount0: 0n, amount1: 0n, amountLBT: 0n, value: 0, accToken0PerShare: 0n, accToken1PerShare: 0n };
export const fillMissingBins = (
  bins: Bin[],
  nbBinsToAddOnEachSide = 0
): Bin[] => {
  if (!bins.length) return bins;

  // add missing bins between first and last bin
  const minId = bins[0].id - nbBinsToAddOnEachSide;
  const maxId = bins[bins.length - 1].id + nbBinsToAddOnEachSide;
  const existingBinIds = new Set(bins.map((bin) => bin.id));

  const filledBins: Bin[] = Array.from(
    { length: maxId - minId + 1 },
    (_, index) => {
      const id = minId + index;
      const newBin = { ...emptyBin, id };
      return existingBinIds.has(id)
        ? (bins.find((bin) => bin.id === id) ?? newBin)
        : newBin;
    }
  );

  return filledBins;
};

export const fillEmptyBinsOnRange = (
  bins: Bin[],
  range: [number, number]
): Bin[] => {
  if (!bins.length) return bins;

  const filledBins: Bin[] = Array.from(
    { length: range[1] - range[0] + 1 },
    (_, index) => {
      const id = range[0] + index;
      const newBin = { ...emptyBin, id };
      return bins.find((bin) => bin.id === id) ?? newBin;
    }
  );

  return filledBins;
};

const getTick = (timestamp: number | Date | string, x: number) =>
  new Date(Math.floor(new Date(timestamp).getTime() / x) * x);

export const getDailyTick = (timestamp: number | Date | string = Date.now()) =>
  getTick(timestamp, ONE_DAY);

export const filledEmptyRewardsWeeks = (
  points: GlobalLeaderboardItemDetails[]
) => {
  const res: GlobalLeaderboardItemDetails[] = [];
  let lastFriday = getDailyTick().getTime();
  while (new Date(lastFriday).getDay() !== 5) {
    lastFriday = lastFriday - ONE_DAY;
  }
  const weeks = new Set(points.map((r) => r.date.getTime()));
  for (let date = lastFriday; date > launchTimestamp; date -= ONE_WEEK) {
    if (weeks.has(date))
      res.push(points.find((r) => r.date.getTime() === date)!);
    else
      res.push({
        date: new Date(date),
        userAddress: '',
        discordScore: 0,
        referralScore: 0,
        totalScore: 0,
        makerScore: 0,
        swapScore: 0,
        points: 0
      });
  }

  return res;
};

export function filledEmptyIncentivesWeeks(rewards: RewardDetails[]) {
  if (rewards.length === 0) return [];

  const startEpoch = 37;
  const lastEpoch = currentEpoch.id - 1;

  const existingEpochs = new Set(rewards.map((r) => r.rewardEpoch));

  const rewardMap: Record<number, RewardDetails[]> = {};

  for (const r of rewards) {
    if (!rewardMap[r.rewardEpoch]) {
      rewardMap[r.rewardEpoch] = [];
    }
    rewardMap[r.rewardEpoch].push(r);
  }

  const result: RewardDetails[] = [];

  for (let epoch = startEpoch; epoch <= lastEpoch; epoch++) {
    if (existingEpochs.has(epoch)) {
      rewardMap[epoch].forEach((r, i) => {
        if (i === 0) return;
        rewardMap[epoch][0] = {
          ...rewardMap[epoch][0],
          makerRewardsWithStreakRaw:
            rewardMap[epoch][0].makerRewardsWithStreakRaw +
            ';' +
            r.makerRewardsWithStreakRaw,
          makerRewardsWithStreak:
            rewardMap[epoch][0].makerRewardsWithStreak +
            r.makerRewardsWithStreak
        };
      });
      result.push(rewardMap[epoch][0]);
    } else {
      result.push({
        rewardEpoch: epoch,
        userAddress: '',
        poolAddress: '',
        makerRewards: 0,
        makerRewardsWithStreak: 0,
        percentOfTotalFees: 0,
        makerScore: 0,
        makerRank: 0,
        rewardPercentage: 0,
        rewardToken: 'JELLY',
        makerRewardsRaw: '0',
        makerRewardsWithStreakRaw: '0',
        pairName: '0',
        rewardAmount: 0,
        weeklyStreak: 0,
        accruedFeesX: '0',
        accruedFeesY: '0',
        accruedFeesL: '0',
        accruedFeesUsd: 0,
        accruedFeesUsdEffective: 0
      });
    }
  }
  return result;
}

// Autonomous Liquidity
// Sum the values for different autonomous liquidity vault for a specific pool
export const sumVaults = (vaults: Vault[]): Bin[] => {
  const summedBins: Bin[] = vaults.reduce((result, { bins }) => {
    bins.forEach((bin) => {
      const existingBinIndex = result.findIndex((b) => b.id === bin.id);
      if (existingBinIndex === -1) {
        result.push({
          ...bin
        });
      } else {
        result[existingBinIndex].amount0 += bin.amount0;
        result[existingBinIndex].amount1 += bin.amount1;
        result[existingBinIndex].amountLBT += bin.amountLBT;
        result[existingBinIndex].value += bin.value;
      }
    });

    return result;
  }, [] as Bin[]);

  return summedBins.sort((a, b) => (a.id > b.id ? 1 : -1));
};
export const sumBinAmounts = (bins: Bin[]) =>
  bins.reduce(
    (acc, bin) => ({
      amount0: acc.amount0 + bin.amount0,
      amount1: acc.amount1 + bin.amount1
    }),
    defaultDeposit
  );

const getNbSignificantNumbers = (amount: bigint): number =>
  Math.floor(Math.log10(Number(amount === 0n ? 1n : amount))) + 1;

export const tokenAmountToSignificant = (tokenAmount: TokenAmount): string =>
  tokenAmount.toSignificant(getNbSignificantNumbers(tokenAmount.raw));

export const getBinValue = (
  token0: Token,
  token1: Token,
  amount0: bigint,
  amount1: bigint,
  activeId: number,
  binStep: number
) => {
  const price = getPriceFromId(activeId, binStep);
  const priceAdjusted = price * 10 ** (token0.decimals - token1.decimals);
  const priceAsFraction = toFraction(priceAdjusted);
  const value = Number(
    new TokenAmount(token0, amount0)
      .multiply(priceAsFraction)
      .add(new TokenAmount(token1, amount1))
      .toSignificant(6)
  );
  return value;
};

export const parseSlot = (thread: number, period: number): number =>
  genesisTimestamp + period * ONE_PERIOD + (thread / 2) * 1000;

export const calculateTVL = (
  amount0: TokenAmount,
  amount1: TokenAmount,
  token0Value: number,
  token1Value: number
) => {
  let tvl = new Fraction(0n);
  if (token0Value) tvl = tvl.add(amount0).multiply(toFraction(token0Value));
  if (token1Value) tvl = tvl.add(amount1.multiply(toFraction(token1Value)));

  return Number(tvl.toSignificant(6));
};

export const calculateAPR = (usdPerDay: Fraction, tvl: number): number => {
  if (tvl === 0 || isNaN(tvl)) return 0;
  const apr = usdPerDay.divide(toFraction(tvl)).multiply(365n).multiply(100n);
  return Number(apr.toSignificant(6));
};

export const calculateRewardAPR = (
  rewardTokens: Reward['rewardTokens'],
  epoch: Epoch,
  tvl: number
) => {
  const usdPerDay = rewardTokens.reduce(
    (acc, rewardToken) =>
      acc.add(
        calculateRewardsPerDay(rewardToken, epoch).multiply(
          toFraction(
            rewardToken.address === JELLY.address
              ? JELLY_VALUE
              : rewardToken.dollarValue
          )
        )
      ),
    new Fraction(1n)
  );

  return calculateAPR(usdPerDay, tvl);
};

export const calculateRewardsPerDay = (
  rewardInfo: Reward['rewardTokens'][number],
  epoch: Epoch
) => {
  const {
    token: { address, decimals }
  } = rewardInfo;
  const epochDuration = Math.round(
    (epoch.to.getTime() - epoch.from.getTime()) / ONE_DAY
  );
  const rewardToken = new BaseToken(CHAIN_ID, address, decimals);
  return new TokenAmount(rewardToken, rewardInfo.amount).divide(
    BigInt(epochDuration)
  );
};

export const getEpochFromId = (id: number): Epoch => {
  return {
    id,
    from: new Date(genesis + (id - 1) * epochDuration),
    to: new Date(genesis + id * epochDuration - 1)
  };
};

export const getEpochFromDate = (date: Date): Epoch => {
  const epochId = Math.floor((date.getTime() - genesis) / epochDuration);
  return getEpochFromId(epochId + 2);
};

export const parseScore = (score: number) => {
  return round(score, 0).toLocaleString();
};

const convertToSubscript = (number: number) =>
  String(number)
    .split('')
    .map((digit) => String.fromCharCode(8320 + parseInt(digit)))
    .join('');

/**
 * Formats a number string using scientific notation with subscript zeros.
 *
 * @param num - The number string or number to format.
 * @param precision - The number of decimal places to include in the formatted string. Default is 3.
 * @returns The formatted number string.
 */
export function formatNumberWithSubscriptZeros(
  num: string | number,
  precision = 3
): string {
  const hasE = num.toString().includes('e');
  const numberStr = hasE
    ? parseFloat(num.toString()).toFixed(18)
    : num.toString();
  const number = typeof num === 'number' ? num : parseFloat(numberStr);
  const isNegative = number < 0;
  const absNumberStr = isNegative ? numberStr.slice(1) : numberStr;

  if (number === 0 || absNumberStr.replace(/0|\./g, '') === '') {
    return '0';
  }

  const [part0, part1 = ''] = absNumberStr.split('.');

  if (/^0*$/.test(part1)) {
    return isNegative ? `-${part0}` : part0;
  }

  const leadingZerosMatch = part1.match(/^0+/);
  if (leadingZerosMatch) {
    const leadingZerosCount = leadingZerosMatch[0].length;

    if (leadingZerosCount > 2) {
      const smallCount = convertToSubscript(leadingZerosCount);
      const result = `${part0}.0${smallCount}${part1
        .slice(leadingZerosCount, leadingZerosCount + precision)
        .replace(/0+$/, '')}`;
      return isNegative ? `-${result}` : result;
    } else {
      const result = `${part0}.${part1
        .slice(0, precision + leadingZerosCount)
        .replace(/0+$/, '')}`;
      return isNegative ? `-${result}` : result;
    }
  } else {
    const result = `${part0}.${part1.slice(0, precision).replace(/0+$/, '')}`;
    return isNegative ? `-${result}` : result;
  }
}

export const changeQuantityWithFixedPriceRaw = (
  quantity: string | undefined,
  isToken0: boolean,
  targetPrice: Fraction,
  token0: Token | undefined,
  token1: Token | undefined
): { qty0: string; qty1: string } => {
  let qty0 = '';
  let qty1 = '';

  if (!quantity) return { qty0, qty1 };

  if (token0 === undefined || token1 === undefined) {
    if (isToken0) qty0 = quantity;
    else qty1 = quantity;
    return { qty0, qty1 };
  }

  isToken0 ? (qty0 = quantity) : (qty1 = quantity);
  const qty = parseUnits(quantity, (isToken0 ? token0 : token1).decimals);
  if (qty <= 0n) return { qty0, qty1 };

  const fraction = isToken0
    ? targetPrice.multiply(qty)
    : targetPrice.invert().multiply(qty);
  const qtyBigInt = fraction.quotient;
  const qtyParsed = tokenAmountToSignificant(
    new TokenAmount(isToken0 ? token1 : token0, qtyBigInt)
  );
  if (qtyBigInt === 0n) isToken0 ? (qty1 = '') : (qty0 = '');
  else isToken0 ? (qty1 = qtyParsed) : (qty0 = qtyParsed);
  return { qty0, qty1 };
};

export const buildExplorerLink = (
  param: 'address' | 'block' | 'operation',
  id: string
): string => {
  const networkQuery = NETWORK === 'buildnet' ? '?network=buildnet' : '';
  return `${EXPLORER}/${param}/${id}${networkQuery}`;
};

export const cleanAddress = (address: string | undefined) =>
  address?.replace('_', '') || ''; // TEMP: handle MAS-WMAS

export const toToken = (token: PrismaToken) =>
  new Token(
    CHAIN_ID,
    token.address,
    token.decimals,
    '',
    token.symbol,
    token.name
  );

/**
 * Filters pools based on the search value.
 * @param search symbol or symbols separated by a space
 * @param pools list of pools
 * @returns
 */
export const filterPools = (search: string, pools: Pool[]): Pool[] => {
  const symbols = search.toLowerCase().split(' ');
  // Search for pools with the token symbol if the search value is one symbol,
  // otherwise search for pools with the token symbols if the search value is two symbols.
  const filteredPools = pools.filter((pool) => {
    const symbol0 = pool.token0.symbol.toLowerCase();
    const symbol1 = pool.token1.symbol.toLowerCase();
    return (
      (symbol0.includes(symbols[0]) || symbol1.includes(symbols[0])) &&
      (symbols.length === 1 ||
        symbol0.includes(symbols[1]) ||
        symbol1.includes(symbols[1]))
    );
  });
  return filteredPools;
};

/**
 * Shortens dayjs `fromNow` output
 * @param str
 * @returns
 */
export const shortenDate = (str: string) =>
  str
    .replace(' years', 'y')
    .replace(' year', 'y')
    .replace(' months', 'mo')
    .replace(' month', 'mo')
    .replace(' days', 'd')
    .replace(' day', 'd')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' minutes', 'm')
    .replace(' minute', 'm')
    .replace(' seconds', 's')
    .replace(' second', 's')
    .replace(' ago', '')
    .replace('a fews', 'a few s')
    .replace('am', '1m')
    .replace('ad', '1d')
    .replace('anh', '1h');

export const convertPointsToJELLY = (points: number, epoch: number) => {
  if (epoch < 37) return points / POINTS_BY_JELLY_BEFORE_37;
  else return points / POINTS_BY_JELLY;
};

export const u8ArrayToString = (array: Uint8Array): string =>
  String.fromCharCode(...array);

export const DELIMITER = ':';
export const createKey = (...args: any[]): string =>
  args.map(String).join(DELIMITER);

export const binStepToDepth = (binStep: number) => {
  switch (binStep) {
    case 1:
      return 200;
    case 20:
    case 15:
      return 1000;
    default:
      return 2500;
  }
};

export const clamp = (num: number, min: number, max: number) => {
  return Math.max(min, Math.min(num, max));
};
