import { LimitOrder, SwapParameters, StartDCAParameters } from '@dusalabs/sdk';
import { Args, MassaUnits } from '@massalabs/massa-web3';
import { ArrayTypes, MAX_GAS_CALL } from '@massalabs/web3-utils';
import {
  dcaManagerSC,
  factorySC,
  faucetSC,
  governanceSC,
  limitOrderSC,
  routerSC,
  vaultManagerSC
} from './config';
import { AutopoolConfig } from './constants';
import { WMAS } from './tokens';
import { ICallData } from './types';
import { baseClient } from './w3';

const ONE_BILLION = 1_000_000_000n;
const callData: Pick<ICallData, 'coins' | 'fee' | 'maxGas'> = {
  coins: 100n * MassaUnits.mMassa, // 0.1 MAS
  fee: await baseClient
    .publicApi()
    .getMinimalFees()
    .then(
      (res) => (res >= 10n * MassaUnits.mMassa ? res : 10n * MassaUnits.mMassa) // 0.01 MAS
    )
    .catch(() => 100n * MassaUnits.mMassa), // 0.1 MAS
  maxGas: ONE_BILLION
};

// ==================================================== //
// ====                    SWAP                    ==== //
// ==================================================== //

export const buildSwapTx = (params: SwapParameters): ICallData => {
  return {
    ...callData,
    targetAddress: routerSC,
    targetFunction: params.methodName,
    parameter: params.args,
    coins: params.value,
    maxGas: ONE_BILLION * 2n
  };
};

// ==================================================== //
// ====                ADD LIQUIDITY               ==== //
// ==================================================== //

export const buildAddLiquidityTx = (
  token0: string,
  token1: string,
  binStep: number,
  amount0: bigint,
  amount1: bigint,
  amount0Min: bigint,
  amount1Min: bigint,
  activeIdDesired: number,
  idSlippage: number,
  deltaIds: number[],
  distributionX: bigint[],
  distributionY: bigint[],
  to: string,
  method: string
): ICallData => {
  const masToSend =
    140_100n * MassaUnits.uMassa +
    BigInt(deltaIds.length) * 45_600n * MassaUnits.uMassa;
  const args = new Args()
    .addString(token0)
    .addString(token1)
    .addU64(toBI(binStep))
    .addU256(amount0)
    .addU256(amount1)
    .addU256(amount0Min)
    .addU256(amount1Min)
    .addU64(toBI(activeIdDesired))
    .addU64(toBI(idSlippage))
    .addArray(deltaIds.map(BigInt), ArrayTypes.I64)
    .addArray(distributionX, ArrayTypes.U256)
    .addArray(distributionY, ArrayTypes.U256)
    .addString(to)
    .addU64(BigInt(Date.now() + 600_000));
  if (method === 'addLiquidityMAS') args.addU64(masToSend);

  return {
    ...callData,
    targetAddress: routerSC,
    targetFunction: method,
    parameter: args,
    coins:
      method === 'addLiquidityMAS'
        ? (token0 === WMAS.address ? amount0 : amount1) + masToSend
        : masToSend,
    maxGas: MAX_GAS_CALL
  };
};

// ==================================================== //
// ====              REMOVE LIQUIDITY              ==== //
// ==================================================== //

export const buildRemoveLiquidityTx = (
  tokenA: string,
  tokenB: string,
  binStep: number,
  amountXMin: bigint,
  amountYMin: bigint,
  ids: number[],
  amounts: bigint[],
  to: string,
  method: string
): ICallData => {
  const isToken0MAS = tokenA == WMAS.address;
  const isToken1MAS = tokenB == WMAS.address;
  const isMAS = method == 'removeLiquidityMAS';
  const masToSend =
    109_600n * MassaUnits.uMassa +
    BigInt(ids.length) * 10_200n * MassaUnits.uMassa;

  if (!isToken0MAS && isToken1MAS) {
    [tokenA, tokenB] = [tokenB, tokenA];
    [amountXMin, amountYMin] = [amountYMin, amountXMin];
  }

  const args = new Args();
  if (!isMAS) args.addString(tokenA);
  args
    .addString(tokenB)
    .addU32(binStep)
    .addU256(isMAS ? amountYMin : amountXMin)
    .addU256(isMAS ? amountXMin : amountYMin)
    .addArray(ids.map(BigInt), ArrayTypes.U64)
    .addArray(amounts, ArrayTypes.U256)
    .addString(to)
    .addU64(BigInt(Date.now() + 600_000));

  return {
    ...callData,
    targetAddress: routerSC,
    targetFunction: method,
    parameter: args,
    maxGas: MAX_GAS_CALL,
    coins: masToSend
  };
};

// ==================================================== //
// ====             INCREASE ALLOWANCE             ==== //
// ==================================================== //

export const buildIncreaseAllowanceTx = (
  token: string,
  spender: string,
  amount: bigint
): ICallData => {
  const args = new Args().addString(spender).addU256(amount > 0n ? amount : 0n);

  return {
    ...callData,
    targetAddress: token,
    targetFunction: 'increaseAllowance',
    parameter: args
  };
};

export function buildSetApprovalForAll(
  token: string,
  spender: string
): ICallData {
  const args = new Args().addU8(+true).addString(spender);

  return {
    ...callData,
    targetAddress: token,
    targetFunction: 'setApprovalForAll',
    parameter: args
  };
}

// ==================================================== //
// ====            AUTONOMOUS LIQUIDITY            ==== //
// ==================================================== //

export const buildALDeposit = (
  token0Address: string,
  token1Address: string,
  type: AutopoolConfig,
  amountX: bigint,
  amountY: bigint
): ICallData => {
  const isToken0MAS = token0Address === WMAS.address;
  const isToken1MAS = token1Address === WMAS.address;
  const isMAS = isToken0MAS || isToken1MAS;

  if (!isToken0MAS && isToken1MAS) {
    [token0Address, token1Address] = [token1Address, token0Address];
    [amountX, amountY] = [amountY, amountX];
  }

  const args = new Args()
    .addString(token0Address)
    .addString(token1Address)
    .addU64(toBI(type));

  if (!isMAS) args.addU256(amountX);

  args
    .addU256(amountY)
    .addU256(0n) // high slippage with mas, why ??? 3.42 instead of 3.60
    .addU256(0n); // high slippage with mas, why??? 3.42 instead of 3.60

  return {
    ...callData,
    targetAddress: vaultManagerSC,
    targetFunction: isMAS ? 'depositMAS' : 'deposit',
    parameter: args,
    maxGas: MAX_GAS_CALL,
    coins: isMAS ? amountX : callData.coins
  };
};

export const buildAutonomousWithdrawAll = (
  token0Address: string,
  token1Address: string,
  type: AutopoolConfig,
  method: string
): ICallData => {
  const isMAS = method === 'withdrawAllMAS';
  const args = new Args().addString(
    isMAS && token0Address === WMAS.address ? token1Address : token0Address
  );
  if (!isMAS) args.addString(token1Address);
  args.addU64(toBI(type));

  return {
    ...callData,
    targetAddress: vaultManagerSC,
    targetFunction: method,
    parameter: args,
    maxGas: ONE_BILLION * 2n
  };
};

// ==================================================== //
// ====                     DCA                    ==== //
// ==================================================== //

export const buildDCATx = (param: StartDCAParameters): ICallData => {
  const args = new Args()
    .addU256(param.amountEachDCA)
    .addU64(toBI(param.interval))
    .addU64(toBI(param.nbOfDCA))
    .addArray(param.tokenPath, ArrayTypes.STRING)
    .addU32(param.threshold)
    .addU64(toBI(param.startIn));

  return {
    ...callData,
    targetAddress: dcaManagerSC,
    targetFunction: 'startDCA',
    parameter: args,
    maxGas: ONE_BILLION * 2n
  };
};

export const buildStopDCATx = (id: number): ICallData => {
  const args = new Args().addU64(BigInt(id));

  return {
    ...callData,
    targetAddress: dcaManagerSC,
    targetFunction: 'stopDCA',
    parameter: args
  };
};

// ==================================================== //
// ====                  LIMIT ORDER                 ==== //
// ==================================================== //

export const buildAddOrderTx = (order: LimitOrder): ICallData => {
  const args = new Args().addSerializable(order);

  return {
    ...callData,
    targetAddress: limitOrderSC,
    targetFunction: 'addLimitOrder',
    parameter: args,
    coins: MassaUnits.oneMassa
  };
};

export const buildRemoveOrderTx = (index: number): ICallData => {
  const args = new Args().addU64(BigInt(index));

  return {
    ...callData,
    targetAddress: limitOrderSC,
    targetFunction: 'removeLimitOrder',
    parameter: args
  };
};

export const buildClaimFeesTx = (
  address: string,
  account: string,
  ids: number[]
): ICallData => {
  const args = new Args()
    .addString(account)
    .addArray(ids.map(BigInt), ArrayTypes.U64);

  return {
    ...callData,
    targetAddress: address,
    targetFunction: 'collectFees',
    parameter: args
  };
};

// ==================================================== //
// ====                    FAUCET                  ==== //
// ==================================================== //

export const buildFaucetTx = (): ICallData => {
  return {
    ...callData,
    targetAddress: faucetSC,
    targetFunction: 'claim',
    parameter: new Args()
  };
};

// ==================================================== //
// ====                GOVERNANCE                  ==== //
// ==================================================== //

export const buildVoteTx = (
  proposalId: number,
  support: boolean
): ICallData => {
  const args = new Args().addU32(proposalId).addBool(support);

  return {
    ...callData,
    targetAddress: governanceSC,
    targetFunction: 'vote',
    parameter: args,
    coins: 10n * MassaUnits.mMassa
  };
};

export const buildCreateProposalTx = (
  title: string,
  description: string,
  startBlock: number,
  endBlock: number
): ICallData => {
  const args = new Args()
    .addString(title)
    .addString(description)
    .addU64(toBI(startBlock))
    .addU64(toBI(endBlock));
  return {
    ...callData,
    targetAddress: governanceSC,
    targetFunction: 'propose',
    parameter: args
  };
};

// ==================================================== //
// ====                    WMAS                    ==== //
// ==================================================== //

export const buildWrapTx = (amount: bigint): ICallData => {
  return {
    ...callData,
    targetAddress: WMAS.address,
    targetFunction: 'deposit',
    parameter: new Args(),
    coins: amount
  };
};

export const buildUnwrapTx = (_amount: bigint, target: string): ICallData => {
  const amount = _amount <= BigInt(2 ** 64 - 1) ? _amount : 0n;
  const args = new Args().addU64(amount).addString(target);

  return {
    ...callData,
    targetAddress: WMAS.address,
    targetFunction: 'withdraw',
    parameter: args,
    coins: 0n
  };
};

// ==================================================== //
// ====                  CREATE                    ==== //
// ==================================================== //

export const buildCreatePoolTx = (
  tokenA: string,
  tokenB: string,
  activeId: number,
  binStep: number
): ICallData => {
  return {
    ...callData,
    targetAddress: factorySC,
    targetFunction: 'createLBPair',
    parameter: new Args()
      .addString(tokenA)
      .addString(tokenB)
      .addU32(activeId)
      .addU32(binStep),
    coins: MassaUnits.oneMassa * 10n
  };
};

// ==================================================== //
// ====                  REWARD                    ==== //
// ==================================================== //

export const buildClaimRewardTx = (
  marketAddress: string,
  epoch: bigint,
  rewarder: string,
  tokenAddress?: string
): ICallData => {
  const args = new Args().addString(marketAddress).addU64(epoch);
  if (tokenAddress) args.addString(tokenAddress);

  return {
    ...callData,
    targetAddress: rewarder,
    targetFunction: 'claim',
    parameter: args
  };
};

// ==================================================== //
// ====                    MISC                    ==== //
// ==================================================== //

const toBI = (value: number) =>
  BigInt(isNaN(value) ? 0 : Math.floor(Math.abs(value)));
