import { useState, useContext, useEffect } from 'react';
import {
  EventDecoder,
  LIQUIDITY_ROUTER_METHODS,
  SWAP_ROUTER_METHODS
} from '@dusalabs/sdk';
import {
  Client,
  ICallData as _ICallData,
  withTimeoutRejection
} from '@massalabs/massa-web3';
import { toast } from 'react-toastify';
import TxToast from 'components/TxToast';
import { AccountWrapperContext } from 'context/AccountWrapperContext';
import { rewarderMasSC, NETWORK, rewarderJellySC } from 'utils/config';
import { pollAsyncEvents } from 'utils/eventPoller';
import { printBigintIsh } from 'utils/methods';
import {
  getAddLimitOrderDescription,
  getAddLiquidityDescription,
  getAllowanceDescription,
  getApprovalDescription,
  getClaimFeesDescription,
  getRemoveLiquidityDescription,
  getStartDCADescription,
  getSwapDescription,
  getCreatePoolDescription,
  getClaimRewardsDescription
} from 'utils/portfolio';
import { addToRecentTransactions, setPendingDCACreation } from 'utils/storage';
import { JELLY, MASSA, WMAS } from 'utils/tokens';
import { ICallData, Transaction } from 'utils/types';

interface SendTransactionProps {
  data?: ICallData;
  onTxConfirmed?: () => void;
}

const isSwapTx = (tx: ICallData) =>
  SWAP_ROUTER_METHODS.includes(tx.targetFunction as any);
const isLiquidityTx = (tx: ICallData) =>
  LIQUIDITY_ROUTER_METHODS.includes(tx.targetFunction as any);

const generateDescription = async (
  data: ICallData,
  events: string[]
): Promise<string> => {
  if (isSwapTx(data)) {
    const isNativeIn = data.targetFunction.includes('MASFor');
    const isNativeOut = data.targetFunction.endsWith('MAS');
    return getSwapDescription(events, data.parameter, isNativeIn, isNativeOut);
  }
  if (data.targetAddress == WMAS.address) {
    if (data.targetFunction == 'deposit') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const amount = printBigintIsh(WMAS, data.coins!);
      return `Wrap ${amount} MAS`;
    } else if (data.targetFunction == 'withdraw') {
      const paramAmount = data.parameter.getArgsList()[0].value;
      const amount = printBigintIsh(WMAS, paramAmount);
      return `Unwrap ${amount} MAS`;
    }
  }

  switch (data.targetFunction) {
    case 'addLiquidity':
    case 'addLiquidityMAS':
      return getAddLiquidityDescription(events, data.parameter);
    case 'removeLiquidity':
    case 'removeLiquidityMAS':
      return getRemoveLiquidityDescription(events, data.parameter);
    case 'collectFees':
      return getClaimFeesDescription(data.targetAddress, events);
    case 'increaseAllowance':
      return getAllowanceDescription(data.parameter, data.targetAddress);
    case 'setApprovalForAll':
      return getApprovalDescription(data.targetAddress);
    case 'createLBPair':
      return getCreatePoolDescription(events);
    case 'startDCA':
      setPendingDCACreation(events[0].split(';?!')[-1]);
      return getStartDCADescription(data.parameter);
    case 'stopDCA':
      const dcaId = data.parameter.getArgsList()[0].value;
      return 'DCA id ' + dcaId + ' stopped';
    case 'addLimitOrder':
      return getAddLimitOrderDescription(data.parameter, events[0]);
    case 'removeLimitOrder':
      const orderId = data.parameter.getArgsList()[0].value;
      return `Limit order #${orderId} cancelled`;
    case 'claim':
      if (data.targetAddress == rewarderMasSC)
        return getClaimRewardsDescription(events, MASSA);
      else if (data.targetAddress == rewarderJellySC)
        return getClaimRewardsDescription(events, JELLY);
      else return 'Claim tokens from faucet';
    case 'deposit':
    case 'depositMAS':
      return 'Deposit into vault';
    // const pairAddress = await getPoolFromVault(data.targetAddress);
    // return await getDepositDescription(events, pairAddress);
    case 'withdrawAll':
    case 'withdrawAllMAS':
    case 'withdraw':
    case 'withdrawMAS':
      return 'Withdraw from vault';
    // const pairAddress = await getPoolFromVault(data.targetAddress);
    // description = await getWithdrawDescription(events, pairAddress);

    default:
      return 'Transaction successful';
  }
};

export const useSendTransaction = (props: SendTransactionProps) => {
  const [txHash, setTxHash] = useState<string>();
  const [txError, setTxError] = useState<string>();
  const [txEvents, setTxEvents] = useState<string[]>();
  const [success, setSuccess] = useState(false);
  const [isTxPending, setIsTxPending] = useState(false);
  const [isConfirmPending, setIsConfirmPending] = useState(false);
  const pending = isTxPending || isConfirmPending;

  const {
    client,
    connectedAddress: signerAddress,
    selectedProvider,
    refetchDrawer
  } = useContext(AccountWrapperContext);

  useEffect(() => {
    if (!success || !props.onTxConfirmed) return;

    props.onTxConfirmed();
    if (!props.data) return;

    const bypassRefetchMethods = [
      'setApprovalForAll',
      'increaseAllowance',
      'stopDCA'
    ];
    if (!bypassRefetchMethods.includes(props.data.targetFunction))
      refetchDrawer();
  }, [success]);

  const submitTx = async (_data?: ICallData) => {
    if (!client) return;

    setIsConfirmPending(true);
    signTx(_data);
  };

  const signTx = async (_data?: ICallData) => {
    const isArgValid = _data && 'targetAddress' in _data;
    const data = isArgValid ? _data : props.data;
    if (!data) return;

    // leave this here (copy/paste for multisigs)
    console.log('recipient:', data.targetAddress);
    console.log('method:', data.targetFunction);
    console.log(data.parameter.serialize());
    console.log('value:', (data.coins || 0n).toString());

    const network = await selectedProvider?.getNetwork();
    if (network !== NETWORK) {
      alert(
        `Wrong network selected. Please switch your ${selectedProvider
          ?.name()
          .toLowerCase()} wallet to ${NETWORK} to continue.`
      );
      setIsConfirmPending(false);
      return;
    }

    setSuccess(false);

    if (!client) return;

    await client
      .smartContracts()
      .callSmartContract(data)
      .then(async (txId) => {
        setIsConfirmPending(false);
        setIsTxPending(true);
        setTxHash(txId);
        console.log(txId);
        await processTx(txId, client, data).then(() => setIsTxPending(false));
      })
      .catch((err) => {
        if (err.message.includes('prompter is already listening')) {
          err.message = 'A transaction is already in progress';
        } else if (err.message.includes('aborting during HTTP request')) {
          err.message = 'Transaction timeout exceeded';
        }

        console.log(err);
        toast.error(err.message);
        setTxError(err.message);
        setIsConfirmPending(false);
      });
  };

  const processTx = async (
    txId: string,
    _client: Client,
    data: ICallData
  ): Promise<any> => {
    const targetFunction = isSwapTx(data)
      ? 'swap'
      : isLiquidityTx(data)
        ? data.targetFunction.replace('MAS', '')
        : data.targetFunction;
    const pendingToast = toast.loading(
      <TxToast title={`Processing ${targetFunction}`} txId={txId} />,
      { autoClose: false }
    );

    const tx: Omit<Transaction, 'status' | 'description'> = {
      from: signerAddress,
      to: data.targetAddress,
      hash: txId,
      timestamp: Date.now(),
      targetFunction
    };

    try {
      const { isError, eventPoller, events } = await withTimeoutRejection(
        pollAsyncEvents(_client, txId),
        120_000
      );
      eventPoller.stopPolling();

      const eventsMsg = events.map((event) => event.data);
      console.log(eventsMsg);

      if (isError) {
        const errorMsg = EventDecoder.decodeError(
          eventsMsg[eventsMsg.length - 1]
        );
        const errorName = errorMsg.split(':')[0];
        switch (errorName) {
          case 'LBRouter__AmountSlippageCaught':
            throw new Error(
              'Amount slippage limit exceeded, try with an higher amount slippage tolerance'
            );
          case 'LBRouter__IdSlippageCaught':
            throw new Error(
              'Price slippage limit exceeded, try with an higher price slippage tolerance'
            );
          case 'LBRouter__DeadlineExceeded':
            throw new Error('Transaction deadline exceeded');
          case 'LBRouter__InsufficientAmountOut':
            throw new Error('Insufficient amount out');
          default:
            throw new Error(errorName);
        }
      }

      toast.dismiss(pendingToast);
      setSuccess(true);

      const description = await generateDescription(data, eventsMsg).catch(
        () => ''
      );

      addToRecentTransactions(
        {
          ...tx,
          status: 'confirmed',
          description
        },
        signerAddress
      );
      setTxEvents(eventsMsg);
      toast.success(<TxToast title={description} txId={txId} />);
    } catch (err: any) {
      const errorMsg = err.message || 'Something went wrong';
      console.log(err);

      addToRecentTransactions(
        {
          ...tx,
          status: 'failed',
          description: errorMsg
        },
        signerAddress
      );

      setTxError(errorMsg);
      setSuccess(false);
      toast.dismiss(pendingToast);
      toast.error(errorMsg);
    }
  };

  return {
    txError,
    txHash,
    isTxPending,
    isConfirmPending,
    pending,
    success,
    txEvents,
    submitTx
  };
};
